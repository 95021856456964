import { IAllWishlist, IWishlist, IWishlistBaby } from "interface/WishlistInterface";
import { atom } from "recoil";

export const productNoToAddState = atom<number | null>({
    key: 'productNoToAdd',
    default: null,
})

export const productNoToDetachState = atom<number | null>({
    key: 'productNoToDetach',
    default: null,
})

export const productNoToDetachWithIdState = atom<{
    wishlistId: string
    productNo: number
} | null>({
    key: 'productNoToDetachWithId',
    default: null,
})

export const allWishedProductNoState = atom<number[] | null>({
    key: 'allWishedProductNo',
    default: null
})

export const currentWishBabyState = atom<IWishlistBaby | null>({
    key: 'currentWishBaby',
    default: null
})

export const currentWishlistState = atom<IWishlist | null>({
    key: 'currentWishlist',
    default: null
})

export const wishlistByBabyState = atom<IAllWishlist | null>({
    key: 'wishlistByBaby',
    default: null
})

