import Tooltip from '@components/snippets/Tooltip'
import { ButtonBase } from '@mui/material'
import React, { CSSProperties, useEffect, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { isParentState } from 'states/shop/isParent'
import { allWishedProductNoState, productNoToAddState, productNoToDetachState, productNoToDetachWithIdState } from 'states/shop/wishlistState'
import getUserkey from 'util/getUserKey'

interface WishlistToggleButtonProps {
    className?: string
    productNo: number
    wishlistId?: string
    style?: CSSProperties
    refreshFunc?: (uk: string) => Promise<any>
}

const WishlistToggleButton = ({ className, productNo, wishlistId, style, refreshFunc }: WishlistToggleButtonProps) => {
    const isParent = useRecoilValue(isParentState);
    const allWishedProductNo = useRecoilValue(allWishedProductNoState);
    const [productNoToAdd, setProductNoToAdd] = useRecoilState(productNoToAddState);
    const [productNoToDetach, setProductNoToDetach] = useRecoilState(productNoToDetachState);
    const [productNoToDetachWithId, setProductNoToDetachWithId] = useRecoilState(productNoToDetachWithIdState);

    const [uk, setUk] = useState<string | null>(null);
    const [isOnWish, setIsOnWish] = useState<boolean>(false);

    useEffect(() => {
        if (allWishedProductNo !== null) {
            setIsOnWish(allWishedProductNo.find(no => no === productNo) !== undefined)
        }
    }, [allWishedProductNo])

    useEffect(() => {
        const uk = getUserkey()

        if (uk !== '' || uk !== null) {
            setUk(uk)
        }
    }, [])

    if (!isParent) return null
    
    return (<>

        <ButtonBase style={style} className={`rounded-full p-1.5 w-[40px] h-[40px] ${className}`} onClick={() => {
            if (isOnWish && wishlistId) {
                setProductNoToDetachWithId({ wishlistId, productNo })
            } else if (isOnWish && !wishlistId) {
                setProductNoToDetach(productNo)
            } else {
                setProductNoToAdd(productNo)
            }
        }}>
            {/* 24일 기준 -> 맨 처음에 뜨는 상품 번호에 툴팁이 뜰 예정 */}
            {productNo === 110478739 && 
                <Tooltip 
                    tipMsg="위시리스트 콕콕!"
                    bgColor="#635348"
                    textColor="white"
                    tailDirection="bottom-tail"
                    position="fixed bottom-10 min-w-[120px]"
                    ElmentInView={true}
                />}
            <img src={`/assets/heart_${isOnWish ? 'on' : 'off'}.png`} className="w-full aspect-auto" />
        </ButtonBase>
    </>)
}

export default WishlistToggleButton