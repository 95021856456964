export const priceComma = (price:number|null|undefined) =>{
    if(price === null || price === undefined) return "0";
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const priceToWon = (price:number) =>{
    const manUnit = Math.floor(price / 10000) 
    const won = manUnit === 0 ? `${manUnit}` : `${manUnit}만`

    return won
}