import React, { useState } from 'react'
import ButtonBase from '@mui/material/ButtonBase'
import { Popup } from 'konsta/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons'

export interface CustomPopupProps {
    opened: boolean // popup open 상태
    onClose: () => void // popup 닫기 이벤트
    onSecondClose ?: () => void // 닫기 버튼 이벤트2 ( 버튼 function이 한 개 더 필요 시 )
    title?: string | React.ReactNode // 제목으로 사용할 텍스트. \n으로 줄바꿈 ex.`글에 문제가 있나요?\n이 글에 우려되는 부분이 있어요 :(`
    children: string | React.ReactNode // 내용을 사용할 텍스트 또는 react node. \n으로 줄바꿈
    cancelText?: string // 취소 버튼에 렌더링할 텍스트
    okText?: string // 확인 버튼에 렌더링할 텍스트
    onCancel?: () => void // 취소 이벤트
    onOk: () => Promise<any> | any // 확인 이벤트
    oneButton?: boolean
    breakall?:boolean
    noPadding?: boolean
}

const CustomPopup = ({ opened, onClose, onSecondClose, title, children, cancelText, okText, onCancel, onOk, oneButton = false, breakall, noPadding = false }: CustomPopupProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleCancelButton = () => {
        onCancel && onCancel()
        onClose()
    }

    return (
        <Popup 
            size="min-w-[300px] text-center"
            opened={opened}
            onBackdropClick={onSecondClose ? onSecondClose : onClose}
            style={{
                transform: opened ? '' : 'translateY(100vh) translateX(-50%)'
            }}
            className={breakall ? `break-all rounded-[0.6em] md:rounded-[0.8em] flex flex-col` : `rounded-[0.6em] md:rounded-[0.8em] flex flex-col`}>
                <h2 className={`text-[1.1em] font-medium whitespace-pre-line ${noPadding ? '' : 'mt-7 mb-[0.5em]'}`}>
                    {title && title}
                </h2>
                {typeof children === 'string' ? (
                    <p className={`whitespace-pre-line text-[#666] ${noPadding ? '' : 'mb-[2em]'}`}>{children}</p>
                ) : (
                    <div className={`whitespace-pre-line ${noPadding ? '' : 'mb-[2em]'}`}>
                        {children}
                    </div>
                )}
                <div className="border border-t-[#F4F4F4] font-medium text-[0.9em]">
                    {!oneButton && (
                        <ButtonBase
                            onClick={handleCancelButton}
                            style={{
                                padding: '1em 0',
                                width: '50%',
                            }}>
                            {cancelText || '닫기'}
                        </ButtonBase>
                    )}
                    <ButtonBase
                        disabled={isLoading}
                        onClick={async () => {
                            setIsLoading(true)
                            await onOk()
                            setIsLoading(false)
                        }}
                        className={oneButton ? 'w-full' : 'w-1/2'}
                        style={{
                            padding: '1em 0',
                            color: '#D0422A',
                            borderLeft: '1px solid #F4F4F4'
                        }}>
                        {isLoading ? (
                            <FontAwesomeIcon icon={faSpinnerThird} color="#D0422A" className="animate-spin w-[1em] h-[1em]" />
                        ) : `${okText ?? '확인'}`}
                    </ButtonBase>
                </div>
        </Popup>
    )
}

export default CustomPopup