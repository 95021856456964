import moment from "moment";
import React, { useEffect, useState } from "react";

/**
 * 타이머를 사용하는 커스텀 훅의 반환값
 * @param timeLeft 남은 시간. day, hour, min, sec
 * @param timerByDay 남은 일자. 24시간 이상 남은 경우 - 일자만 표시 / 24시간 아래로 남은 경우 - 시간만 표시
 * @param timerByDate 남은 일자, 시간, 분, 초. '3일 00:00:00'
 * @param timerByHour 남은 시간, 분, 초. '87:33:12'
 * @param periodRatio 남은 시간의 비율. 0 ~ 100
 * @param isBeforeEnd 종료 전인지 여부. 종료 후면 false
 * @param isBeforeStart 시작 전인지 여부. 시작 후면 false
 * @param isInvalid 날짜가 유효하지 않은지 여부. 시작일자나 종료일자가 유효하지 않은 날짜면 true
 * @param isLoadingTimer 타이머가 로딩 중인지 여부. 타이머를 계산하는 중이면 true
 */
export interface ITimerReturn {
    timeLeft: {
      day: number,
      hour: number,
      min: number,
      sec: number 
    } | null
    timerByDay: string
    timerByDate: string
    timerByHour: string
    periodRatio: number
    isBeforeEnd: boolean
    isBeforeStart: boolean
    isInvalid: boolean
    isLoadingTimer: boolean
}

/**
 * 타이머를 사용하는 커스텀 훅
 * @param startDate 시작일자. 주어지지 않으면 타이머를 계산하지 않음.
 * @param endDate 종료일자. 주어지지 않으면 타이머를 계산하지 않음.
 * @returns ITimerReturn
 */
export default (startDate: string | null, endDate: string | null): ITimerReturn => {
  const [periodRatio, setperiodRatio] = useState<number>(0);
  const [timeLeft, setTimeLeft] = useState<{ day: number, hour: number, min: number, sec: number } | null>(null);
  const [timerByDay, setTimerByDay] = useState<string>(''); // 24시간 이상 남은 경우 - 일자만 표시 / 24시간 아래로 남은 경우 - 시간만 표시
  const [timerByDate, setTimerByDate] = useState<string>(''); // 3일 00:00:00
  const [timerByHour, setTimerByHour] = useState<string>(''); // 87:33:12
  // const [isNotYet, setIsNotYet] = useState<boolean>(false);
  const [isBeforeStart, setIsBeforeStart] = useState<boolean>(true);
  const [isBeforeEnd, setIsBeforeEnd] = useState<boolean>(true);
  const [isInvalid, setIsInvalid] = useState<boolean>(false);
  const [isLoadingTimer, setIsLoadingTimer] = useState<boolean>(false);

  /* ----------------------------------- 타이머 ---------------------------------- */
    // 한자리수이면 0 추가하는 함수
    const addZeroString = (num: number): string => String(num).length === 1 ? '0' + String(num) : String(num)

    // 타이머의 텍스트를 그리는 함수
    const displayTimerText = () => {
        setIsLoadingTimer(true)

        const startM = moment(startDate)
        const endM = moment(endDate)
        const curM = moment()

        if (!startM.isValid() || !endM.isValid()) {
            setIsInvalid(true)
            setIsLoadingTimer(false)
            return
        }

        const startVal = startM.valueOf()
        const endVal = endM.valueOf()
        const curVal = curM.valueOf()
        
        const period = endVal - startVal
        const diff = endVal - curVal

        const day = Math.floor(diff / (1000 * 60 * 60 * 24))
        const hour = Math.floor((diff / (1000 * 60 * 60)) % 24)
        const min = Math.floor((diff / (1000 * 60)) % 60)
        const sec = Math.floor(diff / 1000 % 60)

        const diffDay = day;
        const diffHour = addZeroString(hour);
        const diffMin = addZeroString(min);
        const diffSec = addZeroString(sec);

        const isNotYetForSale = startVal - curVal > 0

        setperiodRatio(1 - (diff / period) < 0 ? 0 : (1 - (diff / period)) * 100)
        setTimerByDate((diffDay === 0 ? '' : `${diffDay}일`) + ` ${diffHour}:${diffMin}:${diffSec}`)
        setTimerByHour(`${addZeroString(diffDay * 24 + +(diffHour))}:${diffMin}:${diffSec}`)
        setIsBeforeStart(startVal - curVal > 1000)
        setIsBeforeEnd(endVal - curVal > 1000)
        setTimerByDay(diffDay === 0 ? `${hour !== 0 ? hour + '시간 ' : ''}${min !== 0 ? min + '분 ' : ''}${hour === 0 && sec !== 0 ? sec + '초' : ''}` : `${diffDay}일`)
        setTimeLeft({ day, hour, min, sec })

        setIsLoadingTimer(false)
    }

    // interval
    useEffect(() => {
      // 시작일자나 종료일자가 주어지지 않으면 타이머를 계산하지 않음
        if (!startDate || !endDate) return

        const timerId = setInterval(displayTimerText, 1000)

        return () => { 
            clearInterval(timerId)
        };
    }, [startDate, endDate]);

  return {
    timeLeft,
    timerByDay,
    timerByDate, 
    timerByHour,
    periodRatio,
    isBeforeEnd,
    isBeforeStart,
    isInvalid,
    isLoadingTimer,
  }
};
