import { AnimatePresence, motion } from 'framer-motion'
import { useRouter } from 'next/router'
import React, { CSSProperties, useEffect, useRef, useState } from 'react'
import { priceComma } from 'util/priceComma'
import useTimer from 'util/useTimer'
import { faAlarmClock } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ButtonBase } from '@mui/material'
import Image from 'next/image'
import moment from 'moment'
import { WishlistAddButton } from '@components/shop/WishlistAdd'
import WishlistToggleButton from '@components/shop/WishlistToggleButton'
import { isDesktop } from 'react-device-detect'
import { useRecoilValue } from 'recoil'
import { myCheeseState } from 'states/shop/myCheese'
import { maxCheeseAmt } from 'constant/maxCheese'
import CustomPopup from './CustomPopup'
import axios from 'axios'
import { robertUrl } from 'util/usedUrls'
import { removeWordRegex } from 'util/regularExpression'
import convertPriceToWon from 'util/convertPriceToWon'

export interface ProductListItemProps {
    thumbnail: string
    brandName?: string
    productName: string
    productNo: number
    salePrice: number
    immediateDiscountAmt?: number
    timer?: {
        saleStartYmdt: string
        saleEndYmdt: string
    }
    stickerInfos?: {
        type: string
        label: string
    }[]
    customLink?: {
        pathname: string
        query?: object
        notRefresh?: boolean
    }
    couponDiscountAmt?: number
    priceColor?: string
    className?: string
    badgeNumber?: number | null
    veil?: {
        text?: string
        hidePrice?: boolean
    }
    sectionProductStartYmdt?: string
    hideWish?: boolean
    isDeal?: boolean,
}

const TimerText = ({ 
    startDate,
    endDate, 
    className = ''
}: { startDate: string, endDate: string, className?: string }) => {
    const { timerByDay, timerByDate, timerByHour, isBeforeEnd, isBeforeStart } = useTimer(startDate, endDate)
    // const { timerByDay, timerByDate, timerByHour, isBeforeEnd, isBeforeStart } = useTimer('2022-11-04', '2022-11-11')

    if (!isBeforeEnd) return null

    return (
        <div className={"w-full h-[30px] bg-[#FF6464CC] text-white flex justify-center items-center font-14" + ' ' + className}>
            <AnimatePresence>
                {timerByDay !== '' && (
                    <>
                    <motion.div
                        className="mr-1 mb-0.5"
                        initial={{ scale: 1 }}
                        animate={{ scale: [1.01, 1.1, 1.1, 1.01], rotate: [10, -10, 0, -10, 10], transition: { repeat: Infinity } }}>
                            <FontAwesomeIcon color="#ffffff" icon={faAlarmClock} className="w-[0.9em] h-[0.9em]" />
                    </motion.div>
                    <motion.time
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}>
                            {timerByDay} 후 마감!</motion.time>
                    </>
                )}
            </AnimatePresence>
        </div>
    )
}

export const ProductListItemSkeleton = ({ className = '' }: { className?: string }) => (
    <li className={`text-[1em] text-[#2C2C2C] w-full ${className} overflow-hidden`}>
        <div className="w-full aspect-square bg-[#f4f4f4] mb-[1.5em] rounded"></div>
        <div className="w-[50%] h-[1.1em] mb-[0.4em] bg-[#f4f4f4]"></div>
        <div className="w-[93%] h-[1.1em] mb-[0.4em] bg-[#f4f4f4]"></div>
        <div className="w-[80%] h-[1.1em] bg-[#f4f4f4]"></div>
        <div className="w-3/4 h-[1.3em] mt-[0.9em] bg-[#f4f4f4]"></div>
        <div className="w-[56%] h-[1.3em] mt-[0.5em] bg-[#f4f4f4]"></div>
    </li>
)

export const ProductListItem = ({ 
    thumbnail,
    brandName,
    productName,
    productNo,
    salePrice,
    immediateDiscountAmt = 0,
    timer,
    stickerInfos,
    couponDiscountAmt,
    customLink,
    priceColor = '',
    className = '',
    badgeNumber = null,
    veil,
    sectionProductStartYmdt,
    hideWish = false,
    isDeal = false,
 }: ProductListItemProps) => {
    const router = useRouter()

    const myCheese = useRecoilValue(myCheeseState)

    const discountRatio = Math.ceil(immediateDiscountAmt * 100 / salePrice) // 할인율
    const calculatedPrice = salePrice - immediateDiscountAmt// - ((myCheese > maxCheeseAmt ? maxCheeseAmt : myCheese) * 10) // 할인 적용할 가격 - 치즈까지 적용 // 치즈 적용가는 표기 가격에서 제외한다
    const discountedPrice = calculatedPrice <= 0 ? 0 : calculatedPrice// 최종 할인 적용가 (0보다 작으면 무조건 0으로 표기)

    const liRef = useRef<HTMLLIElement>(null);
    const [liWidth, setLiWidth] = useState<number | null>(null);

    useEffect(() => {
        if (liRef.current) {
            setLiWidth(liRef.current.offsetWidth)
        }
    }, [liRef.current])
    return (
        <li ref={liRef} className={ isDesktop ? `text-[1em] text-[#2C2C2C] list-none relative ${className}` : router.query.multi ? `text-[0.85em] text-[#2C2C2C] list-none  relative ${className}` :`text-[1em] text-[#2C2C2C] list-none  relative ${className}`}>
                <ButtonBase 
                    disabled={Boolean(veil)} // veil 효과가 있는 상태에서는 링크가 클릭되지 않도록 처리
                    onClick={()=>{
                        customLink ? 
                        router.push({
                            pathname : customLink.pathname,
                            query :  customLink?.query ? { ...router.query, ...customLink.query, no : productNo } : { ...router.query, no : productNo }
                        })
                        : router.push({
                            pathname: '/shop/products',
                            query : {
                                ...router.query,
                                no : productNo
                            },
                        }, undefined,
                        {shallow:true})
                    }}
                    className="w-full h-full flex-col justify-start text-left rounded text-[1em] items-start">
                    <div
                        className="border border-[#D9D9D9] rounded mb-[0.6em] relative overflow-hidden w-full aspect-square">
                        <div className="aspect-square bg-[#f9f9f9] rounded object-cover relative">
                            {couponDiscountAmt && couponDiscountAmt > 0 && (
                                <div className="absolute right-0 top-0 p-1 text-white font-semibold text-right text-xs bg-[#ff7393] shadow-lg rounded-bl z-10">
                                    +쿠폰할인
                                </div>
                            )}
                        <Image 
                            fill
                            quality={20}
                            sizes="(max-width: 768px) 100vw, 33vw"
                            loading="lazy"
                            object-fit="cover"
                            decoding='async'
                            src={thumbnail.slice(0, 2) === '//' ? 'https:' + thumbnail : thumbnail} 
                            alt={productName + '의 미리보기 사진2'} />
                            {badgeNumber !== null && <div className={
                                "w-[13%] h-[13%] z-10 text-[80%] font-bold flex items-center justify-center absolute top-0 left-0 rounded-br rounded-tl text-white"
                                + ' ' + (badgeNumber <= 3 ? 'bg-[#EB5176]' : 'bg-[#00000099]')
                            }>{badgeNumber}</div>}
                            
                            {veil && (
                                <div className="w-full h-full absolute top-0 left-0 bg-[#000000cc] z-20 whitespace-pre text-white font-medium flex justify-center items-center text-center">
                                    {veil.text || (moment(sectionProductStartYmdt).month() + 1) + '월 ' + moment(sectionProductStartYmdt).date() + '일' + ' 특가\n오픈 예정'}
                                </div>
                            )}
                        </div>

                        {timer && (
                            <TimerText 
                                className="absolute bottom-0 rounded-br rounded-bl"
                                startDate={timer.saleStartYmdt} 
                                endDate={timer.saleEndYmdt} />
                        )}
                    </div>

                    <p className="w-full text-[#9E9E9E]">{brandName}</p>
                    <p className="w-full min-h-[1.6em] max-h-[3.3em] line-clamp-2 pb-1 text-[0.9em]">{productName}</p>

                    {stickerInfos && stickerInfos[0] && stickerInfos[0].type !== 'TEXT' && (
                        <div className={`w-full aspect-auto z-10 bg-white ${router.query.sectionId ? '' : 'absolute bottom-1'}`}>
                            <img src={stickerInfos[0].label} alt="" className='w-[90%] aspect-auto' />
                        </div>
                    )}

                    {((!veil?.hidePrice && !stickerInfos?.length) || (!veil?.hidePrice && stickerInfos?.length && stickerInfos?.[0]?.type === 'TEXT')) && (
                        <div className="w-full font-medium flex flex-col justify-start mt-1">
                            {couponDiscountAmt !== undefined && couponDiscountAmt !== 0 ? (
                                // 적용될 쿠폰이 있는 경우
                                <>
                                    {/* 1. 쿠폰 할인가 텍스트 */}
                                    <div className='text-[0.9em] leading-4 text-[#D0422A]'>{`쿠폰 할인가`}</div>
                                    {/* 2. 원래 가격 */}
                                    <div className="line-clamp-1 leading-5">
                                        <span className="text-[#8B8B8B] relative line-through">
                                            {/* 2-1. 가격 취소선 */}
                                            <motion.span className="absolute h-[1px] bg-[#8B8B8B] top-1/2 -translate-y-1/2 left-[-3%]"></motion.span>
                                            {/* 2-2. 가격 텍스트 */}
                                            <span className="">{priceComma(salePrice)}원</span>
                                        </span>
                                    </div>
                                    {/* 3. 할인된 가격 */}
                                    {<div className='text-[#D0422A] text-[1.2em] w-full line-clamp-1'>
                                        {priceComma(discountedPrice <= 0 ? 0 : discountedPrice )}원
                                    </div>}
                                </>
                            ) : 
                            ( // 적용될 쿠폰이 없는 경우
                                <>
                                    {/* 할인가가 있는 경우 */}
                                    {discountRatio !== 0 && (
                                        <div className="h-[1.6em] line-clamp-1">
                                            {/* 1. 할인율 */}
                                            <span className="mr-2">{discountRatio}%</span>
                                            {/* 2. 원래 가격 */}
                                            <span className="text-[#8B8B8B] relative line-through">
                                                {/* 2-1. 원래 가격 취소선 */}
                                                <motion.span className="absolute h-[1px] bg-[#8B8B8B] top-1/2 -translate-y-1/2 left-[-3%]"></motion.span>
                                                {/* 2-2. 원래 가격 텍스트 */}
                                                <span className="">{priceComma(salePrice)}원</span>
                                            </span>
                                        </div>
                                    )}
                                    {/* 최종 가격 */}
                                    {<motion.span
                                        className={`${couponDiscountAmt !== undefined && couponDiscountAmt !== 0 ? 'text-[1em]' : 'text-[1.2em] text-[#D0422A]'} ` + 'h-[1.6em] font-medium' + ' ' + `text-[${priceColor}]`}>
                                            {/*가격*/}
                                            {priceComma(discountedPrice)}원
                                    </motion.span>}
                                </>
                            )}
                        </div>
                    )}
                </ButtonBase>
                
                {!hideWish && liWidth && (
                    <WishlistToggleButton 
                        productNo={productNo}
                        className={ `absolute z-10 right-0`}
                        style={{ top: liWidth, transform:  isDeal ? 'translateY(-60px)' : 'translateY(-40px)' }} />
                )}
        </li>
    )

}

interface ProductListProps {
    layout?: 'grid' | 'horizontal'
    searchedKeyword?:string,
    list: any[] | null
    customLink?: {
        pathname: string
        query?: object
        notRefresh?: boolean
    }
    priceColor?: string
    listClassName?: string
    itemClassName?: string
    hasTimer?: boolean
    hasBadge?: boolean
    veil?: {
        text?: string
        hidePrice?: boolean
    }
    style?: CSSProperties,
    isDeal?: boolean,
    couponSticker?:boolean
}

const ProductList = ({ 
    layout = 'grid',
    searchedKeyword,
    list,
    customLink,
    priceColor,
    listClassName = '',
    itemClassName = '',
    hasTimer = false,
    hasBadge = false,
    veil,
    style,
    couponSticker,
    isDeal = false,
 }: ProductListProps) => {
    const router = useRouter()
    const [openPopup, setOpenPopup] = useState<boolean>(false);
    const [popupMsg, setPopupMsg] = useState<string>('');

    const switchLayoutStyle = () => {
        switch (layout) {
            case 'grid':
                return 'w-fit mx-auto grid grid-cols-2 grid-rows-2 gap-x-3 gap-y-5 pb-[34px] text-[0.95em]'
            case 'horizontal':
                return 'grid grid-flow-col gap-3 pb-1 overflow-auto overflow-x-auto hideScrollbar text-[0.95em] pr-4'
            default: // grid
                return 'w-fit mx-auto grid grid-cols-2 grid-rows-2 gap-x-3 gap-y-5 pb-[34px] text-[0.95em]'
        }
    }

    const switchItemStyle = (config?: { short?: boolean}) => {
        switch (layout) {
            case 'grid':
                return config?.short ? 'aspect-[100/162]' : 'aspect-[100/190]';
            case 'horizontal':
                return isDesktop ? 'w-[18vw]' : 'w-[36vw] aspect-[100/190]';
            default: // grid
                return '';
        }
    }

    const agreeMarketing = async() =>{
        await axios.post(
        `${robertUrl.account.consentRecord}`,
            { 
                uk : localStorage.getItem('key'),
                sourceLink : `https://shop.jejememe.com${router.asPath}`,
                consentReason : searchedKeyword
            }
        ).then((r) =>{
            setPopupMsg(r.data.error_msg)
            setOpenPopup(true)
        })
    }
    

    return (<div>
        <CustomPopup
        opened={openPopup}
        onClose={() => setOpenPopup(false)}
        onOk={() => setOpenPopup(false)}
        oneButton={true}>
            {popupMsg}
        </CustomPopup>
        
        <ul className={switchLayoutStyle() + ' ' + listClassName} style={style}>
            {list ? list.map((item, i) => {
                if( !item ) return <React.Fragment key={"keyitem" + i}></React.Fragment>;
                const beforeSale = (moment().valueOf() - moment(item.sectionProductStartYmdt).valueOf()) < 0
                return (
                    <ProductListItem
                        key={`${item.productNo}-${i}`}
                        thumbnail={item.listImageUrls[0]}
                        brandName={item.brandName}
                        productName={removeWordRegex(item.productName)}
                        productNo={item.productNo}
                        salePrice={item.salePrice}
                        immediateDiscountAmt={item.immediateDiscountAmt}
                        timer={hasTimer ? { saleStartYmdt: item.sectionProductStartYmdt, saleEndYmdt: item.sectionProductEndYmdt } : undefined}
                        stickerInfos={item.stickerInfos}
                        customLink={customLink}
                        priceColor={priceColor}
                        couponDiscountAmt={item.couponDiscountAmt}
                        className={switchItemStyle({ short: beforeSale }) + ' ' + itemClassName}
                        badgeNumber={(hasBadge && (i <= 5)) ? (i + 1) : null}
                        veil={beforeSale ? veil : undefined}
                        isDeal={isDeal}
                    />
                )
            }) : (
                <>
                    <ProductListItemSkeleton className={switchItemStyle()} />
                    <ProductListItemSkeleton className={switchItemStyle()} />
                    <ProductListItemSkeleton className={switchItemStyle()} />
                    <ProductListItemSkeleton className={switchItemStyle()} />
                </>
            )}
        </ul>
    </div>)
}

export default ProductList