const combineUrlNParams = <ParamT extends object>(url: string, params: ParamT | object, config?: {
    encodingParamValue?: boolean
}): string => 
    Object.entries(params).reduce((acc: string, [key, value], i) => {
        const prefix = !acc.includes('?') ? '?' : '&'
        let _value = Array.isArray(value) ? value.join(',') : value
        
        if (!_value) return acc

        if (config?.encodingParamValue) {
            _value = encodeURIComponent(_value)
        }

        return acc + prefix + key + '=' + _value
    }, url)

export default combineUrlNParams;
