import { motion, useInView } from "framer-motion"
import { useRef } from "react"

interface ITooltipProps{
    tipMsg : string, // 툴팁 메세지
    bgColor : string, // 배경화면 색 -
    textColor : string, 
    tailDirection ?: string // top-tail, bottom-tail, right-tail, left-tail (말풍선 꼬리 방향) - default 아래 
    position ?: string, 
    extraFunc?:any,
    ElmentInView?:boolean, // viewPort내 존재 시 해당 애니메이션 실행
}

const Tooltip = ({tipMsg, position, bgColor, textColor,tailDirection, extraFunc, ElmentInView} : ITooltipProps) =>{
    
    const useInViewRef = useRef<HTMLDivElement>(null)
    const isInView = useInView(useInViewRef)

    return (<>
        {ElmentInView
        ? (<motion.div
                ref={useInViewRef} 
                onClick={extraFunc}
                initial={ isInView ?{ x: 0, y : 0, opacity : 1 } : { x: 0, y : 0, opacity : 0 }}
                animate={ isInView ? { y : [0, 0.6, 1.6, 2.0, 1.6, 0.6, 0.6, 1.6, 2.0, 1.6, 0.6, 0.6, 1.6, 2.0, 1.6, 0.6, 0.6, 1.6, 2.0, 1.6, 0.6, 0] , opacity : [1, 1, 1, 1, 1, 0]} : {opacity : 1} }
                transition={{ duration: 3}}
                className={`${tailDirection} font-[700] ${position} bg-[${bgColor}] text-[${textColor}] py-1 px-3 rounded-md text-xs`}>
                <span>{tipMsg}</span>
        </motion.div>)
        : (
            <motion.div 
                onClick={extraFunc}
                initial={{ x: 0, y : 0}}
                animate={{ y : [0, 0.8, 1.6, 2.0, 1.6, 0.8, 0] }}
                transition={{ duration: 0.8, repeat: Infinity }}
                className={`${tailDirection} font-[700] ${position} bg-[${bgColor}] text-[${textColor}] py-1 px-3 rounded-md text-xs`}>
                <span>{tipMsg}</span>
            </motion.div>
        )}
    </>)
}

export default Tooltip