import combineUrlNParams from "./combineUrlNParams"

export const removeWordRegex = (
    name : string,
) => { 
    const regex = /\$@.*?@\$/g
    return name.replace(regex, '')
}

export const ManageCodeRegex = (
    type:string ,
    manageCode:string
) =>{

    let match 
    const goodsRegex =  /^\d{9}_(?!.*none)[A-Za-z0-9]{2,30}_[a-zA-Z0-9]{0,10}/
    const printRegex =  /^\d{9}_[a-zA-Z0-9]{2,30}_[a-zA-Z0-9]{0,10}/

    switch(type){
        // 굿즈 상품 여부 정규식
        case('goods'):
            match = goodsRegex.test(manageCode)
            break;
        // 인화 상품 여부 정규식
        case('print'):
            break;
        // 구매 후 제작 상품인지, 네이티브 굿즈인지 체크하는 정규식 
        case('later'):
            const split_manageCode = manageCode.split('_')?.[0]
            match = split_manageCode === 'later'
            break;
        case('option'):
        // 옵션 선택 후 딥링크 타는 상품 여부 정규식
            let code_split = manageCode.split('_')?.[2]
            match = code_split === 'op'
            break;
        default:
        //일반 상품
            break;
    }

    return match
}

export enum GoodsProductType {
    calendar = 'calendar',
    calendar2 = 'calendar2',
    calendar3 = 'calendar3',
    photocard = 'photocard',
    photobook = 'photobook',
    print = 'print',
    frame = 'frame',
    griptok3 = 'griptok3',
    none = 'none'
}

export type GoodsManufactureType = 'fuj' | 'stb' | 'jum' | 'ptm' | 'red' | 'hus' | 'etc'
export const GoodsManufactureKrType =  {
    fuj: '후지필름',
    stb: '스탑북',
    jum: '점보포토',
    ptm: '포토몬',
    red: '레드프린팅',
    hus: '허스크팩토리',
    etc: '기타'
} as const

export interface ManageCodeParsing {
    isGoods: boolean
    getDeepLink: ((productNo: number, optionNo: number, buyPrice?: number, value?: number) => string) | null
    productNo: number
    goodsType: GoodsProductType
    option: 'di' | 'op' | 'ch' | null // di: 단품, op: 옵션, ch: 채널톡
    orderType: 'API' | 'man' | null // 주문처리 방식. API: api, man: 수기처리
    manufacture: GoodsManufactureType | null // 제조사
    aosVersion: number | null
    iosVersion: number | null
    canChangeCount: boolean // 장바구니 수량 변경 유무
}

export const parseManageCode = (manageCode: string | null | undefined) => {
    if (!manageCode) return null

    const regex = /^\d{8,20}_[a-zA-Z0-9]{0,10}_[a-zA-Z0-9]{0,10}_[a-zA-Z0-9]{0,10}_[a-zA-Z0-9]{0,10}_\d{0,5}_\d{0,5}_[a-zA-Z0-9]{0,10}/g
    const match = regex.test(manageCode)

    if (!match) return null

    const [_productNo, _goodsType, _option, _orderType, _manufacture, _aosVersion, _iosVersion, count] = manageCode.split('_')

    return {
        isGoods: true, // 위의 정규식에서 굿즈 상품만 걸러내기 때문에 항상 true -> 추후에 다른 종류의 상품관리코드가 추가되면 수정 필요
        productNo: parseInt(_productNo),
        goodsType: GoodsProductType[_goodsType as keyof typeof GoodsProductType] ?? null,
        option: _option as 'di' | 'op' | 'ch' ?? null,
        orderType: _orderType as 'API' | 'man' ?? null,
        manufacture: _manufacture as GoodsManufactureType ?? null,
        aosVersion: parseInt(_aosVersion),
        iosVersion: parseInt(_iosVersion),
        canChangeCount: count === 'cnt' ? true : false,
        getDeepLink: (productNo: number, optionNo: number, buyPrice?: number, value?: number) => combineUrlNParams(
            `daybabyday://goods`,
            { product: _goodsType, productNo, optionNo, buyPrice, value }
        )
    }
}