export const headerConfig = {
    "clientId": "EOJKLt48FZRbMXZwuyrLYg==",
    "platform" : "PC",
    "version" : "1.0",
    "accessToken" : ""
}

export const NonMemberHeaderConfig = {
    "clientId": "EOJKLt48FZRbMXZwuyrLYg==",
    "platform" : "PC",
    "version" : "1.0",
}

export const serverHeaderConfig = {
    "systemKey" : 'b1hLbVFoS1lUeUZVS1EwV1loaVZMQT09',
    "mallKey" : 'M21OMm8zU1JHUEU1NGFqUURvUklGSk1UNVdvOHc0NkI',
    "version" : '1.0'
}

export const headerConfig2 = {
    "clientId": "EOJKLt48FZRbMXZwuyrLYg==",
    "platform" : "PC",
    "version" : "1.0"
}