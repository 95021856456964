let isDev = false;

const shopDomain =  isDev ? 'https://alpha-shop-api.e-ncp.com' :'https://shop-api.e-ncp.com';
const serverDomain =  isDev ? 'https://alpha-server-api.e-ncp.com' :'https://server-api.e-ncp.com';

const oAuth = '/oauth'
const product = '/products'
const ordersheet = '/order-sheets'
const coupons = '/coupons'
const address = '/addresses'
const profile = '/profile'
const cart = '/cart'
const banner = '/display/banners'
const display = '/display'
const cheese = '/profile/accumulations'
const laterInput = '/later-input'
const inquiry = '/inquiries'

const ShopbyUrl = {
    oAuth : {
        openAccessToken : shopDomain + oAuth + '/openid',
        getTokenInfo: shopDomain + '/openid/token'
    },
    products : {
        products : shopDomain + product,
        options : shopDomain + product + '/options',
        productOption : shopDomain + product,
        search : shopDomain + product + '/search',
        searchByNos : shopDomain + product + '/search-by-nos',
        optionImage : shopDomain + product,
        bestSeller: shopDomain + product + '/best-seller/search',
        favoriteKeywords: shopDomain + product + '/favoriteKeywords',
        groupCd : shopDomain + product + '/group-management-code',
        related : shopDomain + product
    },
    order : {
        ordersheet : shopDomain +ordersheet,
        coupons : shopDomain+ordersheet+'/coupons',
        calculate : shopDomain +ordersheet,
        payments : shopDomain + '/payments/reserve',
        applyCoupon : shopDomain + ordersheet,
        getAppliableCoupon : shopDomain + ordersheet,
        cart : shopDomain + '/cart'
    },
    promotion : {
        myCoupons : shopDomain + coupons,
        issueableByProductNo : shopDomain + coupons + product,
        issuableCoupon :  shopDomain + coupons + '/issuable', // 상품 상관없이 다운로드 받을 수 있는 쿠폰 조회
        downloadCoupon : shopDomain + coupons,
        productCoupon : shopDomain + coupons + '/products'
    },
    addresses : {
        search : shopDomain+address+'/search'
    },
    profile : {
        profile : shopDomain + profile,
        orders : shopDomain + profile + '/orders',
        orderDetail : shopDomain + profile + '/orders',
        orderOption : shopDomain + profile + '/order-options',
        shippingAddress : shopDomain + profile + '/shipping-addresses',
        productInquiries : shopDomain + profile + '/product-inquiries',
        confirm : shopDomain + profile + '/order-options',
        claim : shopDomain + profile + '/claims/cancel',
        refund : shopDomain + profile + '/claims/return'
    },
    cart : {
        createCart : shopDomain + cart,
        getCart : shopDomain + cart,
        deleteCart : shopDomain + cart,
        calculate : shopDomain + cart + '/calculate'
    },
    banners : {
        display : shopDomain + banner,
    },
    display : {
        sections: shopDomain + display + '/sections',
        categories : shopDomain + '/categories',
        categoryDetail : shopDomain + '/categories',
        brands: shopDomain + display + '/brands',
        profileProductInquiry : shopDomain + product + inquiry,
        registerProductInquiry : shopDomain + product,
        deleteProductInquiry : shopDomain + product + inquiry,
    },
    manage : {
        cheeses : shopDomain + cheese,
        inquiry : shopDomain + inquiry,
        inquiryDetail : shopDomain +  inquiry,
    },
    laterInput: {
        order: shopDomain + laterInput + '/order',
        shippings: shopDomain + laterInput + '/shippings',
    }
}

export const ServerByUrl = {
    products : {
        products : serverDomain + '/products',
        addCheese : serverDomain + cheese,
        deleteCheese : serverDomain + cheese
    },
    member : {
        profile : serverDomain + '/profile'
    },
    orders: {
        searchOrders: serverDomain + '/orders',
    }
}

export default ShopbyUrl